import {
  CalendarDaysIcon,
  ChatBubbleLeftIcon,
  CreditCardIcon,
  HomeIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/vue/24/solid';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {SidebarItem, Steps} from '@/types/ui-elements';

export const PRO_PROFILE_SETUP_STEPS: Steps[] = [
  {
    name: 'About you',
    description: 'Help customers in getting to know you a little better.',
    status: 'current',
  },
  {
    name: 'Preferences',
    description: 'Add your business location and hours.',
    status: 'upcoming',
  },
];

export const PRO_SIDEBAR_ITEMS: SidebarItem[] = [
  {displayName: 'Dashboard', routeName: CROSSGRID_PAGE_URLS.pro.dashboard.home, icon: HomeIcon},
  {
    displayName: 'Jobs',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.jobs,
    icon: WrenchScrewdriverIcon,
  },
  {
    displayName: 'Messages',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.messages,
    defaultRoute: 'pro_inbox',
    icon: ChatBubbleLeftIcon,
  },
  {
    displayName: 'Availability',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.availability,
    icon: CalendarDaysIcon,
  },
  {
    displayName: 'Billing',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.billing,
    icon: CreditCardIcon,
  },
  {
    displayName: 'Profile',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.profile,
    icon: UserCircleIcon,
  },
];
