<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Enter a description of the job you need done"
        description=""
        :errors="errors"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #main>
          <!-- Add to form data -->
          <div class="sm:col-span-6">
            <CrossgridLabel id="job_description" label="Job Description" />
            <CrossgridInput
              id="job_description"
              v-model="formData.description"
              type="multiline"
              rows="7"
              placeholder="Enter description of the job you need done"
              :errors="errors" />
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>
<script setup lang="ts">
import {computed, onMounted, reactive, ref, Ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {ProServiceSelectSavedProgress} from '@/types/pro-services';
import {MinimalUser} from '@/types/user';
import {UserJob, UserJobSavedProgress} from '@/types/user-job';
import {getProServiceSelectSavedProgress} from '@/utils/pro-services';
import {getSession} from '@/utils/shared';
import {getUserJobSavedProgress} from '@/utils/user-job';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';

const router = useRouter();
const formData = reactive<UserJob>({} as UserJob);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const isLoading = ref(false);
const userSession: Ref<MinimalUser | null> = ref(null);

const proServiceSelectProgress = ref<ProServiceSelectSavedProgress | null>(
  getProServiceSelectSavedProgress()
);
const userJobSearchProgress = ref<UserJobSavedProgress | null>(getUserJobSavedProgress());

const handleNavigation = (action: string) => {
  if (proServiceSelectProgress.value) {
    if (action === 'back') {
      const route = proServiceSelectProgress.value.service_custom
        ? {
            name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
            params: {source: 'user_job_search'},
          }
        : {
            name: CROSSGRID_PAGE_URLS.pro_service.select.step_two,
            params: {
              source: 'user_job_search',
              slug: proServiceSelectProgress.value.step_one.pro_service,
            },
          };
      return router.push(route);
    }

    const jobSearchProgress: UserJobSavedProgress = {
      step_one: proServiceSelectProgress.value.step_one,
      step_two: proServiceSelectProgress.value.step_two,
      description: formData.description,
    };

    localStorage.setItem('job_search_progress', JSON.stringify(jobSearchProgress));
    return router.push(CROSSGRID_PAGE_URLS.user.job_search.step_two);
  }
};

const actionButtonDisabled = computed(() => {
  return Object.values(formData).length < 1 || Object.values(formData).some(value => value === '');
});

onMounted(() => {
  getSession().then(session => (userSession.value = session));

  if (!userJobSearchProgress.value) {
    if (
      !proServiceSelectProgress.value?.step_two &&
      !proServiceSelectProgress.value?.service_custom
    )
      return router.push({
        name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
        params: {source: 'user_job_search'},
      });
  }

  Object.assign(formData, userJobSearchProgress.value);
});
</script>
