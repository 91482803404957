<template>
  <CrossgridDashboardLayout
    :sidebar="toggleShowFilters"
    :contained-width="!toggleShowFilters"
    sidebar-size="basis-1/3">
    <template #sidebar>
      <div
        :class="[
          toggleShowFilters ? 'lg:w-[300px]' : 'hidden',
          'mx-6 rounded-lg transition lg:mt-8',
        ]">
        <button
          class="absolute right-0 top-0 p-6 text-text-primary dark:text-text-primary-dark lg:hidden"
          @click="toggleShowFilters = !toggleShowFilters">
          <XMarkIcon class="h-6 w-6" />
        </button>
        <div
          class="mt-24 divide-y divide-bg-quaternary px-4 dark:divide-bg-quaternary-dark lg:mt-0 lg:block">
          <div class="pb-8">
            <h3
              class="mb-8 text-lg font-medium text-text-primary dark:text-text-secondary-dark lg:text-base">
              Sort By
            </h3>
            <CrossgridListbox
              v-model="sortOption"
              :options="['Best Match', 'Name', 'Location']"
              label="Filter Services"
              class="my-2" />
          </div>
          <div class="py-8">
            <h3
              class="mb-8 text-lg font-medium text-text-primary dark:text-text-secondary-dark lg:text-base">
              Selected Services
            </h3>
            <div v-for="(service, index) in proListingServicesOffered" :key="index" class="mt-2">
              <label class="inline-flex items-center">
                <input
                  :id="service.name"
                  v-model="checkedList"
                  type="checkbox"
                  :value="service.slug"
                  :disabled="isLastCheckboxDisabled(service.slug)"
                  class="h-6 w-6 cursor-pointer rounded border-border-primary bg-white text-bg-brand-solid focus:ring-bg-brand-solid dark:border-border-primary-dark dark:bg-bg-primary-dark dark:text-bg-brand-solid-dark dark:focus:ring-bg-brand-solid-dark lg:h-5 lg:w-5"
                  @change="toggleCheckBox" />
                <span
                  class="ml-2 cursor-pointer select-none text-text-secondary dark:text-text-secondary-dark lg:text-sm"
                  >{{ service.name }} ({{ service.count }})</span
                >
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #main>
      <div class="col-span-full bg-bg-secondary p-4 dark:bg-bg-secondary-dark">
        <CrossgridCard
          title="Find a Professional"
          :description="description"
          size="md:max-w-2xl"
          :errors="errors"
          :action-button="null"
          @cancel-click="handleNavigation('back')">
          <template #main>
            <div class="col-span-full">
              <div
                v-if="isLoading"
                class="flex justify-center gap-3 font-semibold text-text-secondary dark:text-text-secondary-dark">
                <div
                  class="border-button-brand-fg h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent"></div>
                Searching professionals...
              </div>
              <div v-else class="relative">
                <button
                  v-if="!customJobRequest"
                  class="absolute -top-14 left-0 rounded-full px-5 py-2 font-medium text-text-secondary transition hover:bg-bg-secondary hover:text-text-tertiary-hover dark:text-text-secondary-dark hover:dark:bg-bg-secondary-dark"
                  @click="toggleShowFilters = !toggleShowFilters">
                  <div class="flex items-center gap-2">
                    <FunnelIcon class="h-4 w-4" />
                    {{ !toggleShowFilters ? 'Show Filters' : 'Hide Filters' }}
                  </div>
                </button>

                <div
                  v-if="customJobRequest || noProListingsForSpecificService"
                  class="mt-10 flex flex-col items-center justify-center gap-4 rounded-xl bg-bg-secondary p-6 text-center dark:border-border-secondary-dark dark:bg-bg-primary-dark dark:shadow-bg-secondary-dark">
                  <UserCircleIcon
                    class="h-12 w-12 text-text-secondary dark:text-text-secondary-dark" />
                  <p class="w-3/4 text-lg text-text-secondary dark:text-text-secondary-dark">
                    We couldn't find an automatic direct match in your area, but we're not stopping
                    there.
                  </p>
                  <p class="w-3/4 text-lg text-text-secondary dark:text-text-secondary-dark">
                    You can choose to go back and select a different service or continue with this
                    job request.
                  </p>
                  <p class="w-3/4 text-lg text-text-secondary dark:text-text-secondary-dark">
                    If you continue, our team will start working behind the scenes to find and
                    connect you with the right expert. ✨
                  </p>
                  <div class="w-1/2">
                    <CrossgridButton
                      :icon="CheckBadgeIcon"
                      class="text-primary mt-4 rounded-lg bg-bg-brand-primary px-4 py-2"
                      label="Continue Job Request"
                      @click="handleCustomJobRequest()" />
                  </div>
                </div>

                <div
                  v-for="(pro, index) in proListings"
                  v-else
                  :key="index"
                  class="mb-4 mt-10 rounded-xl border border-solid border-border-secondary bg-bg-primary p-6 shadow-lg shadow-bg-tertiary dark:border-border-secondary-dark dark:bg-bg-primary-dark dark:shadow-bg-secondary-dark">
                  <section
                    class="my-auto flex w-full min-w-[240px] flex-col self-stretch text-text-primary dark:text-text-primary-dark max-md:max-w-full">
                    <div class="flex items-center gap-3">
                      <img
                        loading="lazy"
                        :src="loadProfilePicture(pro.photo)"
                        alt="Avatar for {{ pro.name }}"
                        class="my-auto aspect-square h-16 w-16 rounded-full" />
                      <header class="flex flex-col items-center gap-1 max-md:max-w-full">
                        <h2
                          data-testid="job-name"
                          class="my-auto self-stretch overflow-hidden text-xl leading-none">
                          {{ pro.name }}
                        </h2>
                        <div
                          class="my-auto flex items-center gap-1 self-stretch text-base leading-relaxed text-text-secondary dark:text-text-secondary-dark">
                          <MapPinIcon
                            class="my-auto aspect-square w-5 shrink-0 self-stretch object-contain" />
                          <p class="my-auto self-stretch">
                            {{ pro.location }} - {{ pro.distance_miles }} miles
                          </p>
                        </div>
                      </header>
                    </div>
                    <div
                      class="mt-2.5 flex flex-wrap items-start gap-1 self-start text-center text-xs font-medium text-text-primary max-md:max-w-full"
                      role="navigation"
                      aria-label="Service categories">
                      <div
                        v-for="service in expandedServicesOffered[index]
                          ? pro.services_offered
                          : pro.services_offered.slice(0, 3)"
                        :key="service"
                        class="gap-1 self-stretch rounded-md border border-solid border-border-secondary bg-bg-secondary px-1.5 py-0.5 shadow-sm dark:border-border-secondary-dark dark:bg-bg-secondary-dark dark:text-text-secondary-dark"
                        tabindex="0">
                        {{ service }}
                      </div>
                      <button
                        v-if="!expandedServicesOffered[index] && pro.services_offered.length > 3"
                        class="gap-1 self-stretch rounded-md border border-solid border-border-secondary bg-bg-secondary px-1.5 py-0.5 shadow-sm dark:border-border-secondary-dark dark:bg-bg-secondary-dark dark:text-text-secondary-dark"
                        tabindex="0"
                        @click="toggleExpandServicesOffered(index)">
                        ...
                      </button>
                    </div>
                    <div
                      class="my-5 cursor-pointer rounded-lg bg-bg-secondary p-3 dark:bg-bg-secondary-dark"
                      @click="toggleExpandDescription(index)">
                      <p
                        ref="description"
                        :class="[
                          'break-words text-base leading-6 max-md:max-w-full',
                          {'line-clamp-2': !expandedDescriptions[index]},
                        ]">
                        {{ pro.description }}
                      </p>
                      <div
                        v-if="pro.description.length > 148"
                        class="mt-2.5 text-left text-base font-semibold leading-relaxed text-text-brand-primary max-md:max-w-full"
                        tabindex="0">
                        {{ expandedDescriptions[index] ? 'Show less' : 'Read more' }}
                      </div>
                    </div>
                  </section>
                  <CrossgridButton
                    :icon="ChatBubbleLeftRightIcon"
                    class="text-primary rounded-lg bg-bg-brand-primary px-4 py-2"
                    :label="pro.existing_thread_slug ? 'View Conversation' : 'Message'"
                    @click="handleSendMessage(pro.user_slug, pro.existing_thread_slug)" />
                </div>
                <!-- <div class="flex w-full items-center justify-center p-4">
                  <CrossgridButton
                    v-if="hasMoreJobs"
                    variant="tertiary"
                    @click="showMore"
                    label="Show More" />
                </div> -->
              </div>
            </div>
          </template>
        </CrossgridCard>
      </div>
    </template>
  </CrossgridDashboardLayout>
</template>
<script lang="ts" setup>
import {computed, onMounted, Ref, ref, watch} from 'vue';
import {
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  FunnelIcon,
  MapPinIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/24/solid';
import {AxiosError} from 'axios';
import {useRouter} from 'vue-router';

import {CROSSGRID_IMAGE_HOSTING_BASE_URL, CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIListResponse, APIStandardError} from '@/types/network';
import {ProListing} from '@/types/pro-listings';
import {ProService, ProServiceSelectSavedProgress} from '@/types/pro-services';
import {UserJobSavedProgress} from '@/types/user-job';
import {useHttp} from '@/utils/composables';
import {getProServiceSelectSavedProgress} from '@/utils/pro-services';
import {addHiddenFieldAPIErrors} from '@/utils/shared';
import {getUserJobSavedProgress} from '@/utils/user-job';

import CrossgridDashboardLayout from '@/components/_layout/CrossgridDashboardLayout.vue';
import CrossgridButton from '@/components/atoms/CrossgridButton.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridListbox from '@/components/atoms/CrossgridListbox.vue';

const http = useHttp();
const router = useRouter();

const errors: Ref<APIStandardError | undefined> = ref(undefined);
const toggleShowFilters = ref(false);
const isLoading = ref(false);
const sortOption = ref<'Best Match' | 'Name' | 'Location'>('Best Match');
const sortOptionMapping = {
  'Best Match': 'best_match',
  Name: 'name',
  Location: 'distance_miles',
};
const userJobSearchProgress = ref<UserJobSavedProgress | null>(getUserJobSavedProgress());
const proServiceSelectProgress = ref<ProServiceSelectSavedProgress | null>(
  getProServiceSelectSavedProgress()
);

const proListings: Ref<ProListing[]> = ref([]);
const proListingServicesOffered: Ref<ProService[]> = ref([]);
const expandedServicesOffered = ref<{[key: number]: boolean}>({});
const expandedDescriptions = ref<{[key: number]: boolean}>({});
const checkedList: Ref<string[]> = ref(userJobSearchProgress.value?.step_two || ['']);
const apiListResponse: Ref<APIListResponse | null> = ref(null);

const loadProfilePicture = (photo: string | File | undefined) => {
  if (!photo) {
    return `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/profile_placeholder.png`;
  }
  return photo as string;
};

const toggleExpandDescription = (proID: number) => {
  expandedDescriptions.value[proID] = !expandedDescriptions.value[proID];
};

const toggleExpandServicesOffered = (proID: number) => {
  expandedServicesOffered.value[proID] = !expandedServicesOffered.value[proID];
};

const handleNavigation = (action: string) => {
  if (action === 'back') return router.push(CROSSGRID_PAGE_URLS.user.job_search.step_one);
};

const getProListings = (slug: string, pro_service: string) => {
  isLoading.value = true;
  http
    .get(`/pro/pro_listing/`, {
      params: {
        pro_service_role: slug,
        services_selected: checkedList.value.length > 0 ? checkedList.value?.toString() : null,
        pro_service: pro_service,
        order_by: sortOptionMapping[sortOption.value],
      },
    })
    .then(response => {
      isLoading.value = false;
      apiListResponse.value = response.data;
      proListings.value = response.data.items;
      proListingServicesOffered.value = response.data.all_services;
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
};

const fetchProListings = () => {
  const progress = userJobSearchProgress.value;
  if (progress?.step_one.pro_service_role && progress?.step_two) {
    getProListings(progress.step_one.pro_service_role, progress.step_one.pro_service);
  }
};

const toggleCheckBox = () => {
  if (userJobSearchProgress.value && proServiceSelectProgress.value) {
    userJobSearchProgress.value.step_two = checkedList.value;
    proServiceSelectProgress.value.step_two = checkedList.value;
    localStorage.setItem('job_search_progress', JSON.stringify(userJobSearchProgress.value));
    localStorage.setItem(
      'pro_service_select_progress',
      JSON.stringify(proServiceSelectProgress.value)
    );
  }
  fetchProListings();
};

const addUserJob = () => {
  return http.post('/user/job/', {
    description: userJobSearchProgress.value?.description,
    services_needed: customJobRequest.value ? null : checkedList.value,
    service_custom: customJobRequest.value ? proServiceSelectProgress.value?.service_custom : null,
  });
};

const initiateMessage = (job_slug: string, pro_user_slug: string) => {
  return http.post('/communication/message/', {
    receiver: pro_user_slug,
    job: job_slug,
  });
};

const handleCustomJobRequest = async () => {
  addUserJob()
    .then(() => {
      router.push(CROSSGRID_PAGE_URLS.user.job_search.success);
    })
    .catch(error => {
      errors.value = addHiddenFieldAPIErrors(error.response?.data, errors.value, [
        'service_custom',
        'services_needed',
      ]);
    });
};

const handleSendMessage = async (pro_user_slug: string, existing_thread_slug: string) => {
  const navigateToDashboard = (slug: string) => {
    router.push({
      name: 'user_chat',
      params: {
        slug: slug,
      },
    });
  };

  if (existing_thread_slug) {
    return navigateToDashboard(existing_thread_slug);
  }

  try {
    isLoading.value = true;
    const jobResponse = await addUserJob();
    const messageResponse = await initiateMessage(jobResponse.data.job_slug, pro_user_slug);
    navigateToDashboard(messageResponse.data.thread_slug);
  } catch (error) {
    if (error instanceof AxiosError) {
      errors.value = addHiddenFieldAPIErrors(error.response?.data, errors.value, [
        'service_custom',
        'services_needed',
      ]);
    }
  } finally {
    isLoading.value = false;
  }
};

const isLastCheckboxDisabled = (slug: string) => {
  if (checkedList.value.includes(slug)) {
    return checkedList.value.length <= 1;
  }
};

const customJobRequest = computed(() =>
  proServiceSelectProgress.value?.service_custom ? true : false
);

const noProListingsForSpecificService = computed(() => proListings.value.length < 1);

const description = computed(() => {
  if (customJobRequest.value) {
    return null;
  }

  const count = apiListResponse.value?.total;
  const proDisplay = count === 1 ? `${count} Pro` : `${count} Pro's`;
  const matchDisplay = count === 1 ? 'matches' : 'match';

  return `Displaying ${proDisplay} that ${matchDisplay} your selection.`;
});

onMounted(() => {
  if (!userJobSearchProgress.value) {
    return router.push({
      name: CROSSGRID_PAGE_URLS.user.job_search.step_one,
    });
  }
  if (!proServiceSelectProgress.value?.service_custom) fetchProListings();
});

watch(sortOption, () => {
  fetchProListings();
});
</script>
