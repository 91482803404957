export const CROSSGRID_SUPPORT = {
  phone: '+1 (516) 518-3353',
  email: 'success@crossgrid.com',
};

export const CROSSGRID_API_BASE_URL = '/api/v1';

export const CROSSGRID_PAGE_URLS = {
  pro: {
    signup: {
      step_one: '/pro/signup/1/',
      step_two: '/pro/signup/2/',
      step_three: '/pro/signup/3/',
      success: '/pro/signup/success/',
    },
    profile: {
      setup: {
        one: '/pro/profile/setup/1/',
        two: '/pro/profile/setup/2/',
      },
    },
    dashboard: {
      home: '/pro/dashboard/',
      jobs: '/pro/dashboard/jobs/',
      messages: '/pro/dashboard/messages/',
      availability: '/pro/dashboard/availability/',
      billing: '/pro/dashboard/billing/',
      profile: '/pro/dashboard/profile/',
    },
  },
  user: {
    signup: {
      step_one: '/user/signup/1/',
      step_two: '/user/signup/2/',
      success: '/user/signup/success/',
    },
    job_search: {
      step_one: '/user/job_search/1/',
      step_two: '/user/job_search/2/',
      step_three: '/user/job_search/3/',
      success: '/user/job_search/success/',
    },
    dashboard: {
      messages: '/user/dashboard/messages/',
    },
  },
  pro_service: {
    select: {
      step_one: '/pro_service/select/1/',
      step_two: '/pro_service/select/2/',
    },
  },
  allauth: {
    signup: '/auth/signup/',
    login: '/auth/login/',
    logout: '/auth/logout/',
  },
  general: {
    home: '/',
    signup: '/signup/',
  },
  external: {
    privacy_policy:
      'https://app.termly.io/policy-viewer/policy.html?policyUUID=cf629d86-50a2-4c20-98d0-d742156d8f8d',
    terms_of_service:
      'https://app.termly.io/policy-viewer/policy.html?policyUUID=9ae28891-76c1-462e-b161-29717ccf7d69',
    cookie_policy:
      'https://app.termly.io/policy-viewer/policy.html?policyUUID=ba28d6ee-a1b4-4de0-be25-2db644fcb479',
  },
};

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
const DEV = import.meta.env.DEV;
const IS_ON_DEMAND_INSTANCE = import.meta.env.VITE_IS_ON_DEMAND_INSTANCE;
const ON_DEMAND_URL = import.meta.env.VITE_ON_DEMAND_URL;

const getHostingBaseUrl = () => {
  if (DEV) {
    return 'http://localhost:8005/static/images';
  }

  return IS_ON_DEMAND_INSTANCE
    ? `http://${ON_DEMAND_URL}/static/images`
    : `https://${ENVIRONMENT}.crossgrid.cloud/static/images`;
};

export const CROSSGRID_IMAGE_HOSTING_BASE_URL = getHostingBaseUrl();
