<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Tell us about yourself"
        description=""
        :signup-flow="true"
        :errors="errors"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #main>
          <div class="sm:col-span-3">
            <CrossgridLabel id="first_name" label="First name" />
            <CrossgridInput
              id="first_name"
              v-model="formData.first_name"
              :data-testid="'first-name'"
              type="text"
              autocomplete="given-name"
              placeholder="Enter first name"
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="last_name" label="Last name" />
            <CrossgridInput
              id="last_name"
              v-model="formData.last_name"
              :data-testid="'last-name'"
              type="text"
              autocomplete="family-name"
              placeholder="Enter last name"
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="phone_number" label="Phone" />
            <CrossgridInput
              id="phone_number"
              v-model="formData.phone_number"
              :data-testid="'phone-number'"
              type="text"
              autocomplete="tel"
              placeholder="Enter phone number"
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="zipcode" label="Zipcode" />
            <CrossgridInput
              id="zipcode"
              v-model="formData.zipcode"
              :data-testid="'zipcode'"
              type="text"
              autocomplete="postal-code"
              placeholder="Enter zipcode"
              :errors="errors" />
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, reactive, ref, Ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {ProServiceSelectSavedProgress} from '@/types/pro-services';
import {UserSignupSavedProgress, UserSignupStepOne} from '@/types/user-signup';
import {useHttp} from '@/utils/composables';
import {getProServiceSelectSavedProgress} from '@/utils/pro-services';
import {addHiddenFieldAPIErrors} from '@/utils/shared';
import {getUserSignupSavedProgress} from '@/utils/user-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';

const router = useRouter();
const http = useHttp();

const formData = reactive<UserSignupStepOne>({} as UserSignupStepOne);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const isLoading = ref(false);

const proServiceSelectProgress = ref<ProServiceSelectSavedProgress | null>(
  getProServiceSelectSavedProgress()
);
const userSignupProgress = ref<UserSignupSavedProgress | null>(getUserSignupSavedProgress());

const validateForm = () => {
  http
    .post('/user/user_signup/validate_create_account_pre_form/', formData)
    .then(() => {
      const userSignupProgress = {
        step_one: formData,
      };
      localStorage.setItem('user_signup_progress', JSON.stringify(userSignupProgress));
      return router.push(CROSSGRID_PAGE_URLS.user.signup.step_two);
    })
    .catch(error => {
      errors.value = addHiddenFieldAPIErrors(error.response?.data, errors.value, [
        'services_needed',
        'service_custom',
      ]);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleNavigation = (action: string) => {
  if (action === 'back') {
    if (proServiceSelectProgress.value?.service_custom) {
      return router.push({
        name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
        params: {source: 'user_signup'},
      });
    }
    return router.push({
      name: CROSSGRID_PAGE_URLS.pro_service.select.step_two,
      params: {slug: proServiceSelectProgress.value?.step_one.pro_service, source: 'user_signup'},
    });
  }

  isLoading.value = true;
  validateForm();
};

const actionButtonDisabled = computed(() => {
  return Object.values(formData).length < 4 || Object.values(formData).some(value => value === '');
});

onMounted(() => {
  localStorage.removeItem('job_search_progress');
  localStorage.removeItem('pro_signup_progress');

  if (proServiceSelectProgress.value?.service_custom) {
    formData.service_custom = proServiceSelectProgress.value?.service_custom;
  }

  if (proServiceSelectProgress.value?.step_two) {
    formData.services_needed = proServiceSelectProgress.value?.step_two;
  }

  if (userSignupProgress.value?.step_one) {
    Object.assign(formData, userSignupProgress.value?.step_one);
  }
});

onBeforeMount(() => {
  if (!proServiceSelectProgress.value?.step_two && !proServiceSelectProgress.value?.service_custom)
    return router.push({
      name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
      params: {source: 'user_signup'},
    });
});
</script>
