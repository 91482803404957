import {createRouter, createWebHistory} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {useHttp} from '@/utils/composables';

import CrossgridProChat from '@/components/organisms/CrossgridProChat.vue';
import CrossgridProMessages from '@/components/organisms/CrossgridProMessages.vue';
import NotFound from '@/pages/Errors/NotFound.vue';
import MarketingHome from '@/pages/Marketing/MarketingHome.vue';
import SignupChoice from '@/pages/Marketing/SignupChoice.vue';
import ProDashboardAvailability from '@/pages/Pro/Dashboard/ProDashboardAvailability.vue';
import ProDashboardBilling from '@/pages/Pro/Dashboard/ProDashboardBilling.vue';
import ProDashboard from '@/pages/Pro/Dashboard/ProDashboardIndex.vue';
import ProDashboardJobs from '@/pages/Pro/Dashboard/ProDashboardJobs.vue';
import ProDashboardMessages from '@/pages/Pro/Dashboard/ProDashboardMessages.vue';
import ProDashboardProfile from '@/pages/Pro/Dashboard/ProDashboardProfile.vue';
import ProProfileSetupOne from '@/pages/Pro/Profile/Setup/ProProfileSetupOne.vue';
import ProProfileSetupTwo from '@/pages/Pro/Profile/Setup/ProProfileSetupTwo.vue';
import ProSignupOne from '@/pages/Pro/Signup/ProSignupOne.vue';
import ProSignupSuccess from '@/pages/Pro/Signup/ProSignupSuccess.vue';
import ProSignupThree from '@/pages/Pro/Signup/ProSignupThree.vue';
import ProSignupTwo from '@/pages/Pro/Signup/ProSignupTwo.vue';
import ProServiceSelectOne from '@/pages/ProService/ProServiceSelectOne.vue';
import ProServiceSelectTwo from '@/pages/ProService/ProServiceSelectTwo.vue';
import UserDashboardMessages from '@/pages/User/Dashboard/UserDashboardMessages.vue';
import UserJobSearchOne from '@/pages/User/JobSearch/UserJobSearchOne.vue';
import UserJobSearchSuccess from '@/pages/User/JobSearch/UserJobSearchSuccess.vue';
import UserJobSearchTwo from '@/pages/User/JobSearch/UserJobSearchTwo.vue';
import UserSignupOne from '@/pages/User/Signup/UserSignupOne.vue';
import UserSignupSuccess from '@/pages/User/Signup/UserSignupSuccess.vue';
import UserSignupTwo from '@/pages/User/Signup/UserSignupTwo.vue';

const http = useHttp();

const {
  pro: {
    signup: {
      step_one: pro_signup_one,
      step_two: pro_signup_two,
      step_three: pro_signup_three,
      success: pro_signup_success,
    },
    profile: {
      setup: {one: pro_profile_setup_one, two: pro_profile_setup_two},
    },
    dashboard: {
      home: pro_dashboard,
      jobs: pro_dashboard_jobs,
      messages: pro_dashboard_messages,
      availability: pro_dashboard_availability,
      billing: pro_dashboard_billing,
      profile: pro_dashboard_profile,
    },
  },
  user: {
    signup: {step_one: user_signup_one, step_two: user_signup_two, success: user_signup_success},
    job_search: {
      step_one: user_job_search_one,
      step_two: user_job_search_two,
      success: user_job_search_success,
    },
    dashboard: {messages: user_dashboard_messages},
  },
  pro_service: {
    select: {step_one: pro_service_select_one, step_two: pro_service_select_two},
  },
  general: {home: general_home, signup: general_signup},
} = CROSSGRID_PAGE_URLS;

const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: `${pro_service_select_one}:source/`,
    name: pro_service_select_one,
    component: ProServiceSelectOne,
  },
  {
    path: `${pro_service_select_two}:slug/:source/`,
    name: pro_service_select_two,
    component: ProServiceSelectTwo,
  },
  {
    path: pro_signup_one,
    name: pro_signup_one,
    component: ProSignupOne,
  },
  {
    path: pro_signup_two,
    name: pro_signup_two,
    component: ProSignupTwo,
  },
  {
    path: pro_signup_three,
    name: pro_signup_three,
    component: ProSignupThree,
  },
  {
    path: pro_signup_success,
    name: pro_signup_success,
    component: ProSignupSuccess,
  },
  {
    path: pro_profile_setup_one,
    name: pro_profile_setup_one,
    component: ProProfileSetupOne,
  },
  {
    path: pro_profile_setup_two,
    name: pro_profile_setup_two,
    component: ProProfileSetupTwo,
  },
  {
    path: pro_dashboard,
    name: pro_dashboard,
    component: ProDashboard,
  },
  {
    path: pro_dashboard_jobs,
    name: pro_dashboard_jobs,
    component: ProDashboardJobs,
  },
  {
    path: pro_dashboard_messages,
    name: pro_dashboard_messages,
    component: ProDashboardMessages,
    children: [
      {path: '', redirect: {name: 'pro_inbox'}},
      {path: 'inbox', name: 'pro_inbox', component: CrossgridProMessages},
      {path: 'starred', name: 'pro_starred', component: CrossgridProMessages},
      {path: 'pending', name: 'pro_pending', component: CrossgridProMessages},
      {path: 'hired', name: 'pro_hired', component: CrossgridProMessages},
      {path: 'job_completed', name: 'pro_job_completed', component: CrossgridProMessages},
      {path: 'not_hired', name: 'pro_not_hired', component: CrossgridProMessages},
      {path: 'archived', name: 'pro_archived', component: CrossgridProMessages},
      {path: ':slug', name: 'pro_chat', component: CrossgridProChat},
    ],
  },
  {
    path: pro_dashboard_availability,
    name: pro_dashboard_availability,
    component: ProDashboardAvailability,
  },
  {
    path: pro_dashboard_billing,
    name: pro_dashboard_billing,
    component: ProDashboardBilling,
  },
  {
    path: pro_dashboard_profile,
    name: pro_dashboard_profile,
    component: ProDashboardProfile,
  },
  {
    path: user_signup_one,
    name: user_signup_one,
    component: UserSignupOne,
  },
  {
    path: user_signup_two,
    name: user_signup_two,
    component: UserSignupTwo,
  },
  {
    path: user_signup_success,
    name: user_signup_success,
    component: UserSignupSuccess,
  },
  {
    path: user_job_search_one,
    name: user_job_search_one,
    component: UserJobSearchOne,
  },
  {
    path: user_job_search_two,
    name: user_job_search_two,
    component: UserJobSearchTwo,
  },
  {
    path: user_job_search_success,
    name: user_job_search_success,
    component: UserJobSearchSuccess,
  },
  {
    path: user_dashboard_messages,
    component: UserDashboardMessages,
    children: [
      {
        path: '',
        name: 'user_messages',
        redirect: {name: 'user_inbox'},
      },
      {path: 'inbox', name: 'user_inbox', component: CrossgridProMessages},
      {path: 'starred', name: 'user_starred', component: CrossgridProMessages},
      {
        path: 'archived',
        name: 'user_archived',
        component: CrossgridProMessages,
      },
      {
        path: ':slug/',
        name: 'user_chat',
        component: CrossgridProChat,
      },
    ],
  },
  {
    path: general_home,
    name: general_home,
    component: MarketingHome,
  },
  {
    path: general_signup,
    name: general_signup,
    component: SignupChoice,
  },
];

const router = createRouter({
  history: createWebHistory('/v/'),
  routes,
  scrollBehavior() {
    return {top: 0};
  },
});

router.afterEach(async to => {
  const path = to.fullPath;
  const url = `${window.location.origin}${path}`;
  http.post('/fbq/events/page_view/', {page_url: url}).catch(() => {});

  // Set affiliate id
  const aid = new URLSearchParams(window.location.search).get('aid');
  if (aid) {
    http
      .post('/affiliate/event/', {affiliate_id: aid, event_type: 'link_click'})
      .then(() => {
        localStorage.setItem('aid', aid); // Used later to track conversions
      })
      .catch(() => {});
  }
});

export default router;
