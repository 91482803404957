import {watch} from 'vue';
import {useUserStore} from '@/stores/userStore';

import {APIStandardError, QueryParam} from '@/types/network';
import {MinimalUser} from '@/types/user';

export const queryStringConstructor = (queryObject: QueryParam) => {
  let queryString = '?';
  const str: string[] = [];
  for (const filter in queryObject) {
    if (filter) {
      str.push(`${filter}=${encodeURIComponent(queryObject[filter])}`);
    }
  }
  queryString += str.join('&');
  return queryString;
};

export function getSession(): Promise<MinimalUser | null> {
  const userStore = useUserStore();

  return new Promise<MinimalUser | null>(resolve => {
    if (userStore.user) {
      resolve(userStore.user);
    } else {
      const unwatch = watch(
        () => userStore.user,
        user => {
          if (user) {
            unwatch();
            resolve(user);
          }
        }
      );
    }
  });
}

export const addHiddenFieldAPIErrors = (
  apiResponse: APIStandardError,
  errorObject: APIStandardError | undefined,
  fields: string[],
  fieldsExclusion: boolean = false
) => {
  let hiddenFieldsError = false;
  if (apiResponse.validation_error) {
    apiResponse.validation_error.forEach(error => {
      if (fieldsExclusion ? !fields.includes(error.id) : fields.includes(error.id))
        hiddenFieldsError = true;
    });
  }
  errorObject = apiResponse;
  if (hiddenFieldsError)
    errorObject = {
      unexpected_error: 'Oh no.. We have trouble processing your request 😔',
    };
  return errorObject;
};
