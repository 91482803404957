<template>
  <section class="hidden w-1/3 bg-bg-tertiary dark:bg-bg-secondary-dark md:block">
    <ul>
      <li v-for="(group, index) in groupedCategories" :key="index">
        <ul>
          <li
            v-for="category in group"
            :key="category.routeName"
            :data-testid="`category-${category.displayName.toLowerCase().replace(' ', '-')}`"
            :class="[
              'mx-3 my-2 rounded-xl text-sm hover:bg-bg-secondary dark:bg-bg-secondary-dark hover:dark:bg-bg-tertiary-dark',
              route.name === category.routeName
                ? 'bg-bg-secondary text-text-primary dark:bg-bg-tertiary-dark dark:text-text-primary-dark'
                : 'dark:text-text-secondary-dark',
            ]">
            <RouterLink :to="{name: category.routeName}" class="flex items-center space-x-3 p-3">
              <component :is="category.icon" class="h-5 w-5 shrink-0" aria-hidden="true" />
              <div>{{ category.displayName }}</div>
            </RouterLink>
          </li>
        </ul>
        <div
          v-if="index < Object.keys(groupedCategories).length"
          class="mx-5 border-b border-border-primary dark:border-border-primary-dark"></div>
      </li>
    </ul>
  </section>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {RouterLink, useRoute} from 'vue-router';

import {SidebarCategory} from '@/types/ui-elements';

type Props = {
  categories: SidebarCategory[];
};

const props = defineProps<Props>();
const route = useRoute();

const groupedCategories = computed(() => {
  return props.categories.reduce((groups: Record<number, SidebarCategory[]>, category) => {
    if (!groups[category.type]) {
      groups[category.type] = [];
    }
    groups[category.type].push(category);
    return groups;
  }, {});
});
</script>
