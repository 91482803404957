<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Slot used"
        :description="null"
        :custom-description="true"
        :action-button="null"
        :signup-flow="true"
        :errors="errors"
        @cancel-click="handleNavigation('back')">
        <template #title>Get started now!</template>
        <template #main>
          <div ref="checkoutContainer" class="col-span-full"></div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {onBeforeMount, onBeforeUnmount, onMounted, Ref, ref} from 'vue';
import {loadStripe, StripeEmbeddedCheckout} from '@stripe/stripe-js';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {ProSignupSavedProgress} from '@/types/pro-signup';
import {useHttp} from '@/utils/composables';
import {getProSignupSavedProgress} from '@/utils/pro-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

const router = useRouter();
const http = useHttp();

const checkoutContainer = ref('');
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const proSignupProgress = ref<ProSignupSavedProgress | null>(getProSignupSavedProgress());
let checkoutInstance: StripeEmbeddedCheckout | null = null;

const initializeStripeCheckout = (proSignupProgress: ProSignupSavedProgress) => {
  loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY).then(stripe => {
    http
      .post('/stripe/create_checkout_session/', {email: proSignupProgress?.step_one?.email})
      .then(response => {
        const clientSecret = response.data.client_secret;
        if (stripe && !checkoutInstance) {
          stripe
            .initEmbeddedCheckout({
              clientSecret,
            })
            .then(checkout => {
              checkoutInstance = checkout;
              checkoutInstance.mount(checkoutContainer.value);
            })
            .then(() => {
              const path = router.currentRoute.value.path;
              const url = `${window.location.origin}${path}`;
              const {first_name, last_name, email, phone_number, zipcode} =
                proSignupProgress?.step_one || {};

              http.post('/fbq/events/initiate_checkout/', {
                page_url: url,
                first_name,
                last_name,
                email,
                phone_number,
                zipcode,
              });
            });
        }
      })
      .catch(error => {
        errors.value = error.response?.data;
      });
  });
};

const handleNavigation = (action: string) => {
  if (action === 'back') return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_one);
};

onMounted(() => {
  if (proSignupProgress.value) {
    initializeStripeCheckout(proSignupProgress.value);
  }
});

onBeforeUnmount(() => {
  if (checkoutInstance) checkoutInstance.destroy();
});

onBeforeMount(() => {
  if (!proSignupProgress.value?.step_one)
    return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_one);
});
</script>
