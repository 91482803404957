<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="🎉 Job Requested 🎉"
        description=""
        :cancel-button="null"
        :action-button="null">
        <template #main>
          <div class="col-span-full">
            <ol
              class="text-md list-decimal space-y-8 p-5 text-text-tertiary dark:text-text-tertiary-dark">
              <li>
                Our team already started working behind the scenes to connect you with the right
                expert.
              </li>
              <li>Your dedicated success specialist will reach out to you.</li>
              <li>
                You will pick the Pro you think is best for your dream job (Or maybe just that job
                you've been dreading)
              </li>
            </ol>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {onMounted} from 'vue';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

onMounted(() => {
  localStorage.removeItem('user_signup_progress');
  localStorage.removeItem('job_search_progress');
  localStorage.removeItem('pro_service_select_progress');
});
</script>
