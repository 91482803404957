<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Create your account"
        description=""
        :logo="true"
        size="sm:max-w-md"
        :errors="errors"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        :cancel-button="null"
        :success-message="successMessage"
        @action-click="handleNavigation()">
        <template v-if="paymentComplete" #main>
          <div class="col-span-full">
            <CrossgridLabel id="email" label="Email" />
            <CrossgridInput
              id="email"
              v-model="stripeEmail"
              :data-testid="'email'"
              type="text"
              autocomplete="email"
              placeholder="Enter your email"
              :errors="errors"
              disabled />
          </div>

          <div class="col-span-full">
            <CrossgridLabel id="password1" label="Password" />
            <CrossgridInput
              id="password1"
              v-model="formData.password1"
              :data-testid="'password'"
              type="password"
              autocomplete="new-password"
              placeholder="Create a password"
              :errors="errors" />
          </div>

          <div class="col-span-full">
            <div class="flex items-center">
              <div class="flex items-center">
                <input
                  id="terms-conditions"
                  v-model="termsConditions"
                  :data-testid="'terms-conditions'"
                  aria-describedby="item-conditions-description"
                  name="terms-conditions"
                  type="checkbox"
                  class="h-4 w-4 rounded border-border-primary bg-white text-bg-brand-solid focus:ring-bg-brand-solid dark:border-border-primary-dark dark:bg-bg-primary-dark dark:text-bg-brand-solid-dark dark:focus:ring-bg-brand-solid-dark" />
              </div>
              <div class="ml-2 text-sm">
                <label
                  for="terms-conditions"
                  class="text-text-tertiary dark:text-text-tertiary-dark"
                  >I agree to Crossgrid's
                  <a
                    class="text-text-brand-primary"
                    :href="CROSSGRID_PAGE_URLS.external.terms_of_service"
                    target="_blank"
                    >Terms & Conditions</a
                  ></label
                >
              </div>
            </div>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, reactive, ref, Ref} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {SuccessMessage} from '@/types/common';
import {APIStandardError} from '@/types/network';
import {ProSignupSavedProgress, ProSignupStepTwo} from '@/types/pro-signup';
import {useHttp} from '@/utils/composables';
import {getProSignupSavedProgress} from '@/utils/pro-signup';
import {addHiddenFieldAPIErrors} from '@/utils/shared';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';

const router = useRouter();
const http = useHttp();

const formData = reactive<ProSignupStepTwo>({} as ProSignupStepTwo);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const successMessage: Ref<SuccessMessage | undefined> = ref(undefined);
const termsConditions = ref<boolean>(false);
const isLoading = ref(false);
const paymentComplete = ref(false);
const stripeEmail = ref('');
const proSignupProgress = ref<ProSignupSavedProgress | null>(getProSignupSavedProgress());

const userStore = useUserStore();

const createAccount = () => {
  http
    .post('/pro/pro_signup/create_account/', formData)
    .then(() => {
      userStore.fetchUserSession();
      return router.push(CROSSGRID_PAGE_URLS.pro.signup.success);
    })
    .catch(error => {
      errors.value = addHiddenFieldAPIErrors(
        error.response?.data,
        errors.value,
        ['email', 'password1'],
        true
      );
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleNavigation = () => {
  if (proSignupProgress.value?.step_one) {
    Object.assign(formData, proSignupProgress.value.step_one);
    formData.email = stripeEmail.value; // Use the email from the stripe session.
    // This prevents a user from modifying the url, and using someone else's
    // session to create an account that bypasses the payment page.
    isLoading.value = true;
    const path = router.currentRoute.value.path;
    const url = `${window.location.origin}${path}`;
    formData.page_url = url;
    createAccount();
  }
};

const actionButtonDisabled = computed(() => {
  return (
    Object.values(formData).length < 2 ||
    Object.values(formData).some(value => value === '') ||
    !termsConditions.value
  );
});

onBeforeMount(() => {
  // Make sure that step 3 is done & valid session, otherwise redirect user back
  const sessionID = router.currentRoute.value.query.session_id;

  if (!proSignupProgress.value || !proSignupProgress.value?.step_one || !sessionID)
    return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_two);

  http
    .get('/stripe/session_status/', {params: {session_id: sessionID}})
    .then(response => {
      const session = response.data;
      if (session.session_status === 'complete') {
        successMessage.value = {
          header: "You're all set! Thank you.",
          description: 'Create your account to get started.',
        };
        paymentComplete.value = true;
        stripeEmail.value = session.email;
        formData.stripe_customer_id = session.customer_id;

        // Send Subscribe event
        const path = router.currentRoute.value.path;
        const url = `${window.location.origin}${path}`;
        const {first_name, last_name, email, phone_number, zipcode} =
          proSignupProgress.value?.step_one || {};

        http
          .post('/fbq/events/subscribe/', {
            page_url: url,
            price: session.price,
            subscription_id: session.subscription_id,
            first_name,
            last_name,
            email,
            phone_number,
            zipcode,
          })
          .catch(() => {});

        const aid = localStorage.getItem('aid');

        if (aid) {
          http
            .post('/affiliate/event/', {
              affiliate_id: aid,
              event_type: 'pro_signup',
            })
            .catch(() => {});
        }
      } else {
        errors.value = {unexpected_error: 'Something went wrong with your payment'};
      }
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
});
</script>
