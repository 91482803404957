<template>
  <button
    :type="type"
    :disabled="actionButtonDisabled"
    :data-testid="'crossgrid-button'"
    :class="[
      buttonVariantClass,
      'text-md flex w-full items-center justify-center gap-2 rounded-md px-4 py-2.5 font-semibold shadow-sm ring-1 ring-inset focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-button-primary-border disabled:cursor-not-allowed disabled:bg-bg-disabled disabled:text-text-disabled disabled:ring-border-disabled-subtle dark:disabled:bg-bg-disabled-dark dark:disabled:text-text-disabled-dark dark:disabled:ring-border-disabled-dark-subtle',
    ]"
    @click="(e: Event) => emits('action-click', e)">
    <div v-if="!isLoading" class="flex items-center gap-2">
      <component :is="icon" v-if="icon" class="h-5 w-5" aria-hidden="true" />
      {{ label }}
    </div>
    <div v-else class="flex items-center gap-2">
      <div
        class="h-5 w-5 animate-spin rounded-full border-2 border-solid border-button-primary-fg border-t-transparent"></div>
      {{ label }}
    </div>
  </button>
</template>

<script lang="ts" setup>
import {computed, FunctionalComponent} from 'vue';

import {APIStandardError} from '@/types/network';

type Props = {
  icon?: FunctionalComponent | null;
  errors?: APIStandardError | null;
  label?: string | null;
  actionButtonDisabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
};

const props = withDefaults(defineProps<Props>(), {
  icon: null,
  errors: null,
  label: null,
  actionButtonDisabled: false,
  type: 'button',
  isLoading: false,
  variant: 'primary',
});

const buttonVariantClass = computed(() => {
  const variants = {
    primary:
      'bg-button-primary-bg text-button-primary-fg ring-button-primary-border hover:bg-button-primary-bg-hover dark:bg-button-primary-bg-dark dark:text-button-primary-fg dark:ring-button-primary-border dark:hover:bg-button-primary-bg-hover',
    secondary:
      'bg-bg-secondary text-button-secondary-fg ring-button-secondary-border hover:bg-button-secondary-bg-hover dark:bg-bg-secondary-dark dark:text-button-secondary-fg-dark dark:ring-button-secondary-border-dark',
    tertiary:
      'bg-button-tertiary-bg text-button-tertiary-fg hover:bg-button-tertiary-bg-hover dark:bg-button-tertiary-bg-dark dark:hover:bg-button-tertiary-bg-hover-dark ring-button-secondary-border dark:text-button-secondary-fg-dark dark:ring-button-secondary-border-dark',
  };

  return variants[props.variant];
});

const emits = defineEmits<{
  (e: 'action-click', value: Event): Event;
}>();
</script>
