<template>
  <section
    class="flex w-full flex-col overflow-y-scroll bg-bg-secondary pt-3 dark:bg-bg-tertiary-dark">
    <div class="flex items-center space-x-3 p-3">
      <div class="w-full">
        <CrossgridInput
          :icon="MagnifyingGlassIcon"
          placeholder="Search messages"
          :value="searchQuery"
          @input="handleQueryChange" />
      </div>

      <Menu as="div" class="relative inline-block text-left">
        <MenuButton
          data-testid="sort-button"
          class="h-6 w-6 shrink-0 cursor-pointer text-text-primary dark:text-text-primary-dark">
          <AdjustmentsHorizontalIcon aria-hidden="true" />
        </MenuButton>

        <Transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0">
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-48 rounded-md bg-white shadow-lg dark:bg-bg-tertiary-dark">
            <div class="py-1">
              <MenuItem
                v-for="option in orderByOptions"
                :key="option.label"
                v-slot="{active}"
                :data-testid="`sort-${option.label}`"
                as="template">
                <button
                  :class="[
                    'block w-full px-4 py-2 text-left text-sm text-text-primary dark:text-text-primary-dark',
                    active ? 'bg-gray-100 dark:bg-gray-700' : '',
                  ]"
                  @click="handleOrderBy(option.value)">
                  {{ option.label }}
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>

    <div v-if="props.isLoading" class="flex flex-1 flex-col items-center justify-center">
      <div
        class="flex items-center gap-3 font-semibold text-text-secondary dark:text-text-secondary-dark">
        <div
          class="border-button-brand-fg h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent"></div>
        Loading messages...
      </div>
    </div>

    <div v-else-if="!messages.length" class="flex h-full flex-col items-center justify-center">
      <InboxIcon class="h-10 w-10 text-text-secondary dark:text-text-secondary-dark" />
      <p class="mt-2 text-sm text-text-secondary dark:text-text-secondary-dark">
        No messages available
      </p>
    </div>

    <div v-else>
      <ul>
        <li
          v-for="(message, index) in messages"
          :key="index"
          class="flex items-center border-b p-5 transition last:border-none dark:border-border-primary-dark">
          <div
            :class="[
              message.has_unread ? 'bg-brand-600' : 'bg-transparent',
              'mr-3 h-2 w-2 rounded-full transition-all',
            ]"
            style="flex-shrink: 0"></div>
          <div class="mr-3">
            <CrossgridIconButton
              :data-testid="`star-button-${index}`"
              :icon="message.starred ? StarIconSolid : StarIcon"
              size="lg"
              color="secondary"
              @click="handleUpdateStarred(message.slug)" />
          </div>
          <div class="w-full">
            <RouterLink :to="{name: routeName, params: {slug: message.slug}}">
              <div class="space-y-1">
                <div class="flex min-w-0 items-center gap-1">
                  <h3
                    class="truncate text-sm font-semibold text-text-primary dark:text-text-primary-dark">
                    {{ message.from_user }}
                  </h3>
                  <div
                    v-if="message.pro_listing_name"
                    class="flex items-center text-text-tertiary dark:text-text-tertiary-dark">
                    <div
                      class="mx-1 h-1 w-1 rounded-full bg-text-tertiary dark:bg-text-tertiary-dark"></div>
                    <div class="flex items-center gap-1">
                      <BuildingStorefrontIcon class="h-3.5 w-3.5" />
                      <span class="truncate text-xs font-medium">
                        {{ message.pro_listing_name }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  :data-testid="`message-details-${index}`"
                  class="line-clamp-2 text-sm text-text-secondary dark:text-text-secondary-dark">
                  {{ message.last_message }}
                </div>
              </div>
            </RouterLink>
          </div>
          <CrossgridIconButton
            :data-testid="`archive-button-${index}`"
            :icon="message.archived ? InboxArrowDownIcon : TrashIcon"
            size="lg"
            color="secondary"
            @click="handleUpdateArchived(message.slug)" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {ref} from 'vue';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {
  BuildingStorefrontIcon,
  InboxArrowDownIcon,
  InboxIcon,
  StarIcon as StarIconSolid,
} from '@heroicons/vue/20/solid';
import {StarIcon} from '@heroicons/vue/24/outline';
import {AdjustmentsHorizontalIcon, MagnifyingGlassIcon, TrashIcon} from '@heroicons/vue/24/solid';

import {MessageThread} from '@/types/communication';
import {SortParam} from '@/types/network';

import CrossgridIconButton from '@/components/atoms/CrossgridIconButton.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';

const searchQuery = ref('');

type Props = {
  messages: MessageThread[];
  routeName: string;
  isLoading?: boolean;
};

const props = defineProps<Props>();

interface OrderByOption {
  label: string;
  value: SortParam;
}

const orderByOptions: OrderByOption[] = [
  {label: 'Unread', value: {order_by: '-has_unread'}},
  {label: 'Read', value: {order_by: 'has_unread'}},
  {label: 'Newest to Oldest', value: {order_by: '-timestamp'}},
  {label: 'Oldest to Newest', value: {order_by: 'timestamp'}},
  {label: 'By Starred', value: {order_by: '-starred'}},
];

const emit = defineEmits([
  'update-starred',
  'update-archived',
  'search-query',
  'select-message',
  'order-by',
]);

const handleUpdateStarred = (slug: string) => {
  emit('update-starred', slug);
};

const handleUpdateArchived = (slug: string) => {
  emit('update-archived', slug);
};

const handleOrderBy = (orderByValue: SortParam) => {
  emit('order-by', orderByValue);
};

const handleQueryChange = (event: Event) => {
  const query = (event.target as HTMLInputElement).value;
  emit('search-query', query);
};
</script>
