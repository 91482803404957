<template>
  <CrossgridDashboardLayout sidebar-size="basis-1/3">
    <template #sidebar>
      <CrossgridSteps :steps="PRO_PROFILE_SETUP_STEPS" />
    </template>
    <template #main>
      <CrossgridCard
        title="About you"
        description="Customers like to work with people they know. Help them in getting to know you a
              little bit better!"
        size="md:max-w-2xl"
        :cancel-button="null"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        @action-click="handleNavigation()">
        <template #main>
          <div v-if="errors?.unexpected_error" class="col-span-full">
            <CrossgridErrorMessage :error="errors.unexpected_error" />
          </div>
          <div class="col-span-full sm:col-span-3">
            <p class="mb-4 font-semibold text-text-secondary dark:text-text-secondary-dark">
              Some tips to help you get started:
            </p>
            <ul
              role="list"
              class="space-y-3 text-sm leading-6 text-text-tertiary dark:text-text-tertiary-dark">
              <li v-for="(tip, index) in tips" :key="index" class="flex gap-x-1">
                <CheckCircleIcon
                  class="h-6 w-6 flex-none text-fg-brand-primary"
                  aria-hidden="true" />
                {{ tip }}
              </li>
            </ul>
          </div>

          <div class="col-span-full sm:col-span-3">
            <div
              v-if="isImageLoading"
              class="flex justify-center gap-3 font-semibold text-text-secondary">
              <div
                class="border-button-brand-fg h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent"></div>
              Getting image...
            </div>
            <div v-else>
              <CrossgridImageInput
                id="photo"
                v-model:file-value="formData.photo"
                :image_validation_rules="imageValidationRules"
                :errors="errors" />
            </div>
          </div>

          <div class="col-span-full">
            <CrossgridLabel id="description" label="Business description" />
            <CrossgridInput
              id="description"
              v-model="formData.description"
              type="multiline"
              rows="8"
              placeholder="Enter a description"
              help-text="By adding a detailed description, you provide additional trust to your customers."
              :errors="errors" />
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridDashboardLayout>
</template>

<script lang="ts" setup>
import {computed, onMounted, reactive, Ref, ref} from 'vue';
import {CheckCircleIcon} from '@heroicons/vue/24/outline';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {PRO_PROFILE_SETUP_STEPS} from '@/constants/pro-profile';
import {APIStandardError} from '@/types/network';
import {ImageValidationRules, ProProfileStepOne} from '@/types/pro-profile';
import {ProProfileSetupProgress} from '@/types/pro-signup';
import {useHttp} from '@/utils/composables';
import {getProProfileSetupProgress} from '@/utils/pro-signup';

import CrossgridDashboardLayout from '@/components/_layout/CrossgridDashboardLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridErrorMessage from '@/components/atoms/CrossgridErrorMessage.vue';
import CrossgridImageInput from '@/components/atoms/CrossgridImageInput.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';
import CrossgridSteps from '@/components/atoms/CrossgridSteps.vue';

const tips: string[] = [
  'Use a clear and professional photo',
  'Highlight what you specialize in',
  'Share what separates you from the competition',
  "Remember your audience, customers just want to know you're experienced and they can trust you!",
];

const router = useRouter();
const http = useHttp();

const formData = reactive<ProProfileStepOne>({} as ProProfileStepOne);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const imageValidationRules: Ref<ImageValidationRules> = ref({} as ImageValidationRules);
const progress: Ref<ProProfileSetupProgress | undefined> = ref({} as ProProfileSetupProgress);

const isImageLoading = ref(false);
const isLoading = ref(false);

const updateProProfileAboutYou = () => {
  isLoading.value = true;
  if (!progress.value) {
    return router.push(CROSSGRID_PAGE_URLS.pro.profile.setup.two);
  }
  http
    .post('/pro/pro_profile/about_you/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      isLoading.value = false;
      progress.value = {pro_one: formData};
      handleFile(progress.value.pro_one.photo)
        .then(file => {
          localStorage.setItem(
            'pro_profile_setup_progress',
            JSON.stringify({pro_one: {...progress.value?.pro_one, photo: file}})
          );
          return router.push(CROSSGRID_PAGE_URLS.pro.profile.setup.two);
        })
        .catch(error => {
          errors.value = error.response?.data;
        });
    })
    .catch(error => {
      isLoading.value = false;
      errors.value = error.response?.data;
    });
};

const getImageValidationRules = (): Promise<ImageValidationRules> => {
  return http
    .get('/pro/pro_listing/image_validation_rules/')
    .then(response => {
      return response.data;
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
};

const handleNavigation = () => {
  updateProProfileAboutYou();
};

const setFormData = (data: ProProfileStepOne) => {
  Object.assign(formData, data);
  if (data.photo) {
    formData.photo = base64ToFile(data.photo.toString());
  }
};

const actionButtonDisabled = computed(() => {
  return Object.keys(formData).length < 2 || Object.values(formData).some(value => value === '');
});

const handleFile = (file: File | null) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file as Blob);

    return reader;
  });
};

const base64ToFile = (base64: string) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `profile_image.${base64.split(';')[0].split('/')[1]}`, {type: mime});
};

onMounted(() => {
  isImageLoading.value = true;
  getImageValidationRules()
    .then(validationRules => {
      imageValidationRules.value = validationRules;
      const progress = getProProfileSetupProgress();
      if (progress && progress.pro_one) {
        setFormData(progress.pro_one);
      }
    })
    .finally(() => {
      isImageLoading.value = false;
    });
});
</script>
